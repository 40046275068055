import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { ProjectCard2 } from "./ProjectCard2";
import projImg1 from "../assets/img/project-img1.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.png";
import projImg5 from "../assets/img/project-img5.png";
import projImg6 from "../assets/img/project-img6.png";
import projImg7 from "../assets/img/project-img7.png";
import projImg10 from "../assets/img/project-img10.png";
import projImg12 from "../assets/img/project-img12.png";
import projImg13 from "../assets/img/project-img13.png";
import projImg14 from "../assets/img/project-img14.png";


import projImg20 from "../assets/img/project-img20.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {


  const projects = [
    {
      title: "\"Hands\" series",
      subtitle: "",
      description: "Poker Hands and Blackjack Hands",
      description2: "Card-based Puzzle Games Developed in Unity",
      description3:
          "TLDR:\n" +
          "Developed two card-based puzzle games in Unity using C# as my first professional projects. Worked" +
          " closely with a senior developer, handling all game systems and mechanics, while learning Unity," +
          " version control (Git), and the importance of teamwork. Each project took 1 month to complete.\n\n" +
          "Role: Game Developer / Programmer\n\n" +
          "Tools: Unity, C#, Git (Version Control)\n\n" +
          "Overview:\n" +
          "These card-based puzzle games hold sentimental value as they were my first projects after" +
          " completing my software engineering degree. They marked my entry into the professional world," +
          " coinciding with landing my first job.\n" +
          "Working closely with a senior developer, I took on full responsibility for implementing the game " +
          "systems and mechanics. Our daily code reviews helped me rapidly advance my understanding of Unity," +
          " improve my C# proficiency, and appreciate the importance of version control and teamwork in game" +
          " development. This collaborative approach also allowed me to gain insight into all stages of " +
          "development, from pre-production to post-launch support.\n\n" +
          "Project Duration:\n" +
          " - 1 month for each project.\n\n" +
          "Key Skills Developed:\n" +
          " - Unity: Full development of game systems, mechanics, and user interaction.\n" +
          " - C#: Hands-on experience writing clean and maintainable code for a variety of game scenarios.\n" +
          " - Version Control (Git): Learned the value of proper versioning for managing team collaboration and" +
          " tracking changes.\n",
      description4: "\nPoker hands on Steam",
      description5: "Blackjack Hands on Steam",
      imgUrl: projImg1,
      link: "https://store.steampowered.com/app/1261670/Poker_Hands/?curator_clanid=37117656",
      link2: "https://store.steampowered.com/app/1292250/Blackjack_Hands/?curator_clanid=37117656",
    },
    {
      title: "Knight's Retreat",
      subtitle: "",
      description: "",
      description2: "Chess-based Puzzle Game Developed in Unity",
      description3:
          "TLDR:\n" +
          "Developed a chess-based puzzle game in Unity with a strong focus on quality assurance. Collaborated " +
          "closely with the game designer, identifying bugs and optimization opportunities. Tools were used and " +
          "developed as needed. Project duration: 1 month.\n\n" +
          "Role: Game Developer / Programmer\n\n" +
          "Overview:\n" +
          "This was my second project, where the focus shifted to quality assurance. I worked closely with the" +
          " game designer to conduct extensive tests throughout development. Together, we identified bugs, " +
          "inconsistencies, and areas for optimization. We utilized available tools or developed custom tools" +
          " when necessary to enhance the performance and stability of the game.\n\n" +
          "Project Duration:\n" +
          " - 1 month\n\n" +
          "Key Skills Developed:\n" +
          " - Unity: Developing game systems and interactions.\n" +
          " - C#: Coding game logic and mechanics.\n" +
          " - Version Control (Git): Managing collaboration and version history.\n" +
          " - Quality Assurance: Systematic testing and bug identification.\n" +
          " - Tooling: Developing custom tools to streamline testing and optimization.\n",

      description4: "\nLink to Steam",
      imgUrl: projImg12,
      link: "https://store.steampowered.com/app/1261700/Knights_Retreat/?curator_clanid=37117656",
    },
    {
      title: "Chess Knights: Viking Lands",
      subtitle: "",
      description: "Chess-based Puzzle Game Developed in Unity",
      description2: "",
      description3:
          "TLDR:\n" +
          "Developed level expansion systems for a chess-themed puzzle game based on Nordic culture. Collaborated" +
          " closely with designers and artists to ensure smooth integration of new content. " +
          "Project duration: 1 month.\n\n" +
          "Role: Game Developer / Programmer\n\n" +
          "Overview:\n" +
          "This was the first game in the Chess Knights series, and I joined the project post-launch to work on" +
          " expanding the game’s levels. I collaborated with both the game designer and the artist to create" +
          " a seamless level expansion system, ensuring that all content matched the game’s original style." +
          " The project was successfully completed within the deadline.\n\n" +
          "Project Duration:\n" +
          " - 1 month\n\n" +
          "Key Skills Developed:\n" +
          " - Unity: Implementing level expansion systems.\n" +
          " - C#: Writing maintainable code to support new levels.\n" +
          " - Version Control (Git): Ensuring collaboration with other team members.\n" +
          " - Quality Assurance: Testing for bugs and consistency across levels.\n",

      description4: "\nLink to Steam",
      imgUrl: projImg3,
      link: "https://store.steampowered.com/app/1338980/Chess_Knights_Viking_Lands/?curator_clanid=37117656",
    },
    {
      title: "Chess Knights: Shinobi",
      subtitle: "",
      description: "",
      description2: "Chess-based Puzzle Game Developed in Unity",
      description3:
            "TLDR:\n" +
            "Developed a level expansion subsystem for a chess-themed puzzle game based on oriental culture. Contributed to final credits animation, quality assurance, and handled eastern localization. Project duration: 1 month.\n" +

            "\nRole: Game Developer / Programmer\n" +

            "\nOverview:\n" +
            "As the second installment in the Chess Knights series, this project involved developing a new level expansion subsystem and creating the final credits animation. I worked closely with the game designer and artist, ensuring consistency with the game’s theme. Post-launch, I was responsible for integrating eastern localization.\n" +

            "\nProject Duration:\n" +

            " - 1 month\n" +
            "\nKey Skills Developed:\n" +

            " - Unity: Developing level expansion and credits systems.\n" +
            " - C#: Supporting gameplay and UI development.\n" +
            " - Version Control (Git): Managing updates and version history.\n" +
            " - Quality Assurance: Ensuring functionality and consistency.\n" +
            " - Localization API (I2Loc): Integrating language support for eastern regions.\n",

      description4: "\nLink to Steam",
      imgUrl: projImg4,
      link: "https://store.steampowered.com/app/1383050/Chess_Knights_Shinobi/?curator_clanid=37117656",
    },
    {
      title: "Chess Knights: High Noon",
      subtitle: "",
      description: "",
      description2: "Chess-based Puzzle Game Developed in Unity",
      description3:
            "TLDR:\n" +
            "Led the development of a chess-themed puzzle game set in the Old West. Managed pre-production to launch, handling gameplay, animations, audio, and systems integration. Published in early access. Project duration: 3 months.\n" +
            "\nRole: Game Developer / Programmer\n" +
            "\nOverview:\n" +
            "As the lead tech developer, I coordinated all stages of this project from pre-production through delivery. Working closely with the entire team, I implemented gameplay systems, animations, cinematics, and audio integration using Wwise. The game was released in early access, and although I moved on to other projects before completion, I oversaw the development of the main story expansion.\n" +
            "\nProject Duration:\n" +
            " - 3 months\n" +
            "\nKey Skills Developed:\n" +
            " - Projectgement (Tech): Leading development across multiple disciplines.\n" +
            " - Unity: Implementing core gameplay systems.\n" +
            " - C#: Writing and managing code for all game mechanics.\n" +
            " - Version Control (Git): Managing collaboration and version history.\n" +
            " - Quality Assurance: Conducting tests for bugs and performance.\n" +
            " - Tooling: Creating tools to streamline development.\n" +
            " - Wwise API: Handling audio integration.\n",

      description4: "\nLink to Steam",
      imgUrl: projImg5,
      link: "https://store.steampowered.com/app/1486800/Chess_Knights_High_Noon/?curator_clanid=37117656",
    },
    {
      title: "The Chess Variants Club",
      subtitle: "",
      description: "",
      description2: "Chess-based Puzzle Game Developed in Unity",
      description3:
            "TLDR:\n" +
            "Modified chess AI to support a range of chess variants. Created a dynamic puzzle system, allowing flexible game variant creation. Project duration: 1 month.\n" +
            "\nRole: Game Developer / Programmer\n" +
            "\nOverview:\n" +
            "In this project, I was responsible for adapting an existing chess AI to accommodate a variety of chess variants. My primary task was to modify the AI’s calculations to support these variations, creating a dynamic puzzle system that could generate puzzles based on each variant. This allowed for a highly flexible and replayable experience.\n" +
            "\nProject Duration:\n" +
            " - 1 month\n" +
            "\nKey Skills Developed:\n" +
            " - Unity: Modifying AI for chess variants.\n" +
            " - C#: Implementing puzzle generation logic.\n" +
            " - Version Control (Git): Managing code versions and updates.\n" +
            " - Quality Assurance: Testing the AI’s functionality and flexibility.\n" +
            " - Tooling: Developing systems to support variant creation.\n" +
            " - A.I. Programming for Chess: Modifying algorithms to handle complex game rules.\n",

      description4: "\nLink to Steam",
      imgUrl: projImg6,
      link: "https://store.steampowered.com/app/1292240/The_Chess_Variants_Club/?curator_clanid=37117656",
    },
    {
      title: "\"Chess Games\" series",
      subtitle: "",
      description:"Samurai chess, Wild wild chess and Ragnarok chess",
      description2: "Chess-themed Puzzle Games Developed in Unity",
      description3:
            "TLDR:\n" +
            "Led development for three thematic chess games. Created tools to switch between themes, significantly reducing production time. Managed all gameplay systems, UI, animations, and audio. Project duration: 1 month.\n" +
            "\nRole: Game Developer / Programmer\n" +
            "\nOverview:\n" +
            "This project involved developing three chess games with different themes: Samurai, Wild West, and Norse mythology. I worked as the sole developer, managing all aspects of development from gameplay systems to UI, animations, and audio. The key highlight was developing a tool within the Unity Editor that allowed us to switch between themes, which drastically reduced production time.\n" +
            "\nProject Duration:\n" +
            " - 1 month\n" +
            "\nKey Skills Developed:\n" +
            " - Project Management (Tech): Managing development across multiple themed games.\n" +
            " - Unity: Implementing gameplay systems and switching between themes.\n" +
            " - C#: Supporting dynamic gameplay elements.\n" +
            " - Version Control (Git): Tracking changes and managing updates.\n" +
            " - Quality Assurance: Ensuring functionality across multiple themes.\n" +
            " - Tooling: Developing tools to streamline asset switching.\n" +
            " - Wwise API: Integrating audio for all game themes.\n",


      description4: "\nSamurai chess on Steam",
      description5: "Wild Wild Chess on Steam",
      description6: "Ragnarok Chess on Steam",
      imgUrl: projImg7,
      link: "https://store.steampowered.com/app/1568330/Samurai_Chess/?curator_clanid=37117656",
      link2: "https://store.steampowered.com/app/1582090/Wild_Wild_Chess/?curator_clanid=37117656",
      link3: "https://store.steampowered.com/app/1593180/Ragnarok_Chess/?curator_clanid=37117656",
    },
    {
      title: "Jigsaw chess tale's",
      subtitle: "",
      description: "Shinobi's Way and Jessie 'Boom' James",
      description2: "Chess-based Jigsaw Puzzle Game Developed in Unity",
      description3:
            "TLDR:\n" +
            "Developed two thematic jigsaw puzzle games with chess-shaped pieces. Managed all game systems and UI, and experimented with new gameplay styles. Project duration: 1 month.\n" +
            "\nRole: Game Developer / Programmer\n" +
            "\nOverview:\n" +
            "In this project, I created two jigsaw puzzle games with chess-shaped pieces. These games followed the same structure as previous projects but introduced new gameplay mechanics to provide a fresh challenge. I managed all development aspects, including UI, animations, and audio, and took the opportunity to experiment with different puzzle-solving mechanics.\n" +
            "\nProject Duration:\n" +
            " - 1 month\n" +
            "\nKey Skills Developed:\n" +
            " - Project Management (Tech): Managing multiple puzzle games simultaneously.\n" +
            " - Unity: Developing unique gameplay mechanics for jigsaw puzzles.\n" +
            " - C#: Supporting puzzle logic and UI interactions.\n" +
            " - Version Control (Git): Managing project versions.\n" +
            " - Quality Assurance: Ensuring the functionality of new mechanics.\n" +
            " - Tooling: Developing tools to streamline asset creation and import.\n" +
            " - Wwise API: Integrating audio elements into the puzzles.\n",

      description4: "\nShinobi's Way on Steam",
      description5: "Jessie 'Boom' James on Steam",
      imgUrl: projImg10,
      link: "https://store.steampowered.com/app/1593210/Shinobis_Way__a_jigsaw_chess_tale/?curator_clanid=37117656",
      link2: "https://store.steampowered.com/app/1593220/Jessie_Boom_James__a_jigsaw_chess_tale/?curator_clanid=37117656",
    },
    {
      title: "Chessarama",
      subtitle: "",
      description: "",
      description2: "Chess-inspired Puzzle and Strategy Game Collection Developed in Unity",
      description3:
            "TLDR:\n" +
            "Developed systems for UI, sound (Wwise), and multiple game modes, adding new mechanics to expand on classic chess concepts. Created editor tools using Odin Inspector & Serializer, and handled Steam ID configuration. Project duration: TBD.\n" +
            "\nRole: Game Developer / Programmer\n" +
            "\nOverview:\n" +
            "For this project, I was responsible for creating systems for multiple game modes based on classic chess rules, while also introducing new mechanics to expand each mode. My tasks included developing the UI systems and sound integration using Wwise. Additionally, I created custom editor tools using Odin Inspector & Serializer to automate repetitive tasks like asset creation and navigation within the editor. A key aspect of my role was configuring Steam IDs for demo, test, and release branches, ensuring smooth updates and deployment.\n" +
            "\nProject Duration/Participation:\n" +
            " - 2 years / 1 year and 6 months\n" +
            "\nKey Skills Developed:\n" +
            " - Unity: Implementing core gameplay and UI systems.\n" +
            " - C#: Expanding mechanics and creating custom editor tools.\n" +
            " - Version Control (Git): Managing updates and versions.\n" +
            " - Tooling: Automating tasks with Odin Inspector & Serializer.\n" +
            " - Wwise API: Integrating and managing audio systems.\n" +
            " - Steam ID Configuration: Setting up and managing demo, test, and release branches.\n",

      description4: "Chessarama on Steam",
      description5: "Chessarama on Steam",
      imgUrl: projImg13,
      link: "https://store.steampowered.com/app/1831830/Chessarama/?curator_clanid=37117656",
    },
    {
      title: "Little Sim World",
      subtitle: "",
      description: "",
      description2: "Cozy 2D Life Simulator Developed in Unity",
      description3:
            "TLDR:\n" +
            "Developed systems for world states management in a life simulation game. Created editor tools to streamline asset creation and testing processes. All work was done in C#, with Git used for version control. Project duration: TBD.\n" +
            "\nRole: Game Developer / Programmer\n" +
            "\nOverview:\n" +
            "In this project, I was tasked with developing complex systems for managing world states within a 2D life simulation game. While the specifics of the systems are covered by an NDA, I worked primarily in C#, ensuring the game’s dynamic elements could interact seamlessly. Additionally, I created editor tools using Odin Inspector & Serializer to automate repetitive tasks, making asset creation and in-editor testing more efficient.\n" +
            "\nProject Duration/Participation:\n" +
            " - 2+ years / 1 year and 4 months\n" +
            " - Still on Development by the date of this update (October 2024)\n" +
            "\nKey Skills Developed:\n" +
            " - Unity: Developing core simulation systems.\n" +
            " - C#: Coding world management and dynamic gameplay systems.\n" +
            " - Version Control (Git): Managing project updates.\n" +
            " - Tooling: Creating editor tools to streamline asset workflows using Odin Inspector & Serializer.\n",

      description4: "Little Sim World on Steam",
      description5: "Little Sim World on Steam",
      imgUrl: projImg14,
      link: "https://store.steampowered.com/app/1429880/Little_Sim_World/?curator_clanid=37117656",
    },
  ];
  const publicProjects = [
    // {
    //   title: "Creep Out",
    //   subtitle: "",
    //   description: "4 fun",
    //   description2: "Brackeys game jam 2021.2 project for practicing the concepts that i learned. Pass through all the processes of a gema development by myself.\nProject duration: 1 week",
    //   description3: "\nSkills: \n- Project management \n- Unity\n- C#\n- Quality assurance",
    //   description4: "\nCreep Out on itch.io",
    //   imgUrl: projImg20,
    //   link: "https://edy12345.itch.io/creepoutx",
    // },
    {
      title: "Chessarama",
      subtitle: "",
      description: "Shinobi's Way and Jessie 'Boom' James",
      description2: "An jigsaw puzzle with chess-shaped pieces made in Unity Game Engine with C#, that deliver's a unique new challenge and thematic experience for players who identify with the theme.",
      description3: "Role: Game Developer/Programmer\n\nFollowing the same idea of the previous Chess games in this project the intention was to make 2 identical games and have a variation of theme between them to talk with different types of players, again I was designated as the sole developer of the project, and the same skills were used, because the last project had a similar structure, the only difference was that in this project I had to experiment with developing a different type of gameplay than what I was used to, it was a nice change of pace and a good challenge. \n\nProject duration: 1 month.\n\nSkills: \n- Project management (Tech) \n- Unity\n- C#\n- Version Control (git)\n- Quality assurance\n- Tooling\n- Wwise API",
      description4: "\nShinobi's Way on Steam",
      description5: "Jessie 'Boom' James on Steam",
      imgUrl: projImg13,
      link: "https://store.steampowered.com/app/1831830/Chessarama/",
    },
  ];


  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={8}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>In this section you will find all the projects that I've made under contract in these last years; in each one of them you will find a brief description of my contribution and a list of the skills that were used on that project. If you have any other curiosity, feel free to contact me through any of my social medias avaliable on the header.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Contract Projects</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="second">Personal Projects</Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="second">
                    <Row>
                        {
                          publicProjects.map((project, index) => {
                            return (
                              <ProjectCard2
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane> */}
                    {/* <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane> */}
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}